
#live-area {
  z-index: 1;
  position: relative;
  border-color: @brand-primary;
  display: block;
  float: left;
  width: 100%;
  @media(max-width:@screen-md-min){
    padding-top: ((@grid-gutter-width/2) + @logo-mobile-height);
  }
  img {
    max-width: 100%;
  }
}

#content {
  > section:first-child{
    > div {
      @media(max-width: @screen-sm-min) {
        //padding-top: @logo-mobile-height;
      }
    }
  }
}
section {
  padding: 0 0;
  display:block;
  float:left;
  position: relative;
  width:100%;
  z-index: 1;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center !important;
  padding-top : (@grid-gutter-width * @default-section-padding-factor);
  padding-bottom : (@grid-gutter-width * @default-section-padding-factor);
  .row {

  }
  .container-fluid {
    float: initial;
  }
}
.column {
  padding-top : (@grid-gutter-width * @default-column-padding-factor);
  padding-bottom : (@grid-gutter-width * @default-column-padding-factor);
  &:empty {
    padding-top: 0;
    padding-bottom: 0;
  }
  @media(max-width: @screen-xs-max){
    //padding-left:          floor(((@base-em-size * @line-height-base-value) * @column-horz-phone-padding));
    //padding-right:          floor(((@base-em-size * @line-height-base-value) * @column-horz-phone-padding));

  }
}

.container.wraps-page-content, #sidebar-container {
  padding-top : (@grid-gutter-width /2  );
  padding-bottom : (@grid-gutter-width );
}


article {
  display:block;
  position:relative;
}
@import "archive-feed-blog.less";
