
.navbar {
  z-index: 1;
  display: block;
  min-height: 0px;
}

.nav {
  list-style: none;
  padding-left: 0px;
  margin-bottom: 0px;
  li {
    z-index: 1;
    display: inline-block;
    width: auto;
    position: relative;
    a {
      display: inline-block;
      width: 100%;
    }

    ul {
      z-index: 2;
      position: absolute;
      top: 0px;
      left: 0px;
      display: none;
      li {
        width: 100%;

        a {
          width: 100%;
        }
        ul {
          position: relative;
          width: 100%;
          padding-left: 0em;
          li {
            width: 100%;
            padding-left: 0px;
            a {
              width: 100%;
            }
          }

        }
      }
    }

    a:hover, a:focus, a:active {
      + ul {
        display: block;
      }
    }
    &:hover, &:focus, &:active {
      ul {
        display: block;
      }
    }

  }
}

#top-nav, #main-nav {
  font-family: @headings-font-family;
  text-transform: normal;
  border: 0px solid;
  position: relative;
  z-index: 6;
  > div.container {
    max-width: 100%;
  }
}

#main-nav {
  @media(min-width: @screen-md-min){
    //padding-top: (@grid-gutter-width/2);
  }
  border-top: .5em solid @brand-primary;
  z-index: 9;
  background: @main-nav-background;
  font-size: @main-nav-fsize;
  font-weight: @main-nav-fweight;
  font-style: @main-nav-fstyle;
  line-height: .9;
  padding: 0;
  position: relative;
  @media (max-width: @screen-md-max) {
    font-size: @main-nav-fsize-small;
  }
  .container {
    display: flex;
    flex-wrap: wrap;
    align-items:flex-end;

    a.navbar-brand {
      z-index: 11;
      display: inline-block;
      vertical-align: middle;
      padding: 0px;
      margin: 0px;

      #logo {
        height: @logo-height;
        //width: @logo-width;
        padding-top:(@grid-gutter-width / 2);
        padding-bottom: (@grid-gutter-width / 2);
        position: relative;
        width: auto;
        img {
          width: auto;
          height: 100%;
        }
        @media (max-width: @screen-md-max) {
          height: @logo-adjusted-height;
          //width: @logo-adjusted-width

          padding-top:(@grid-gutter-width / 4);
          padding-bottom: (@grid-gutter-width / 4);
        }
        @media (max-width: @screen-sm-max) {
          height: @logo-mobile-height;

          //width: @logo-mobile-width
        }
        @media (max-width: @screen-xs-max) {
          height: @logo-mobile-height;
          //width: @logo-mobile-width
        }
      }
    }
  }

  .navbar-collapse {
    position: relative;
    display: inline-block;
    float: right;
    margin-left: auto;
    > div {
      display: inline-block;
      > ul {
        vertical-align: middle;
        display: inline-block;
        position: relative;
        li a {
          display: inline-block;
          width: 100%;
        }
        > li {
          font-family: @main-nav-family;
          font-weight: @main-nav-fweight;
          font-style: @main-nav-fstyle;
          text-transform: @main-nav-ftransform;
          line-height: @main-nav-height;
          @media (max-width: @screen-md-max) {
            line-height: @main-nav-adjusted-height;
          }

          > a {
            margin-top: 0.875em;
            line-height: 1;
            display: inline-block;
            vertical-align: middle;
            float: none;
            padding: @main-nav-top-level-padding;
            color: @main-nav-color;
            &:hover, &:focus, &:active {
              color: @main-nav-color-hover;
              &:before {
                color: @main-nav-color-hover;
              }
            }

          }


          > ul {
            line-height: 1.25em;
            //border-top: 5px solid @sub-menu-seperator-color;
            padding: @sub-menu-padding;
            background: @sub-menu-bg;
            font-size: @sub-menu-fsize;
            display: block;
            visibility: hidden;
            overflow: hidden;
            transform-origin: 50% 0;
            transform: rotateX(-90deg);
            transition: transform .25s ease-in-out;
            left: 50%;
            margin-left: @sub-menu-left;

            top: (@sub-menu-top  - 3px);
            width: @sub-menu-width;
            //box-shadow: 1px 4px 3px rgba(0, 0, 0, .25);
            @media (max-width: @screen-md-max) {
              top: @sub-menu-adjusted-top;
              width: @sub-menu-width-adjusted;
              margin-left: @sub-menu-left-adjusted;
            }
            li {
              vertical-align: top;
              overflow: hidden;
              font-family: @sub-menu-family;
              font-weight: @sub-menu-fweight;

              a {
                border-top: 1px solid @sub-menu-seperator-color;
              }


              a {

                overflow: visible;
                padding: @sub-menu-link-padding-vert @sub-menu-link-padding-horz;
                line-height: 1em;
                color: @sub-menu-color;
                &:hover, &:focus, &:active {
                  color: @sub-menu-color-hover;
                }
                &:before {
                  transition: margin-left .5s;
                }

              }
              &.menu-item-has-children {
                a {
                  padding-bottom: @sub-sub-menu-link-padding-vert;
                }
                padding-bottom: @sub-sub-menu-link-padding-vert;
              }
              li {
                border-top: 0px solid @sub-menu-seperator-color;
                padding: 0 0 0 0;

                &:last-child {
                  //padding-bottom: @sub-menu-link-padding-vert;
                  a:last-child {
                    //border-bottom: 1px solid @sub-menu-seperator-color;
                    border-bottom: 0;
                  }
                }
                a {
                  text-indent: 1em;
                  border-top: 1px solid @sub-menu-seperator-color;
                }

                a {
                  padding: @sub-sub-menu-link-padding-vert @sub-sub-menu-link-padding-horz;
                }
              }

            }
          }

        }
        > li:hover ul, > li a:hover + ul, > li:focus ul, > li a:focus + ul > li:active ul, > li a:active + ul {
          visibility: visible;
          transform: rotateX(0deg);
        }
      }
    }
  }
}





#top-nav {
  //padding-top: (@grid-gutter-width / 2 );
  background:transparent;
  font-size: @top-nav-fszie;
  font-weight: @top-nav-fweight;
  font-family: @top-nav-font;
  color: @top-nav-color;
  //border-bottom: @top-nav-border-color 1px solid;
  //width: auto;
  position:absolute;
  left: 0;
  top: 0.8em;
  z-index: 10;
  width: 100%;

  text-align: @top-nav-align;
  .container {
    display: block;
    text-align: right;
  }

  .menu-short-container {
    width: auto;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
  #menu-top-menu {
    width: auto;
    vertical-align: middle;
    display: inline-block;
    float: none;
  }
  ul.nav {
    width: auto;
    vertical-align: bottom;
    display: inline-block;
    margin-right: 0em;
    margin-left: 2em;
    li.active a {
    }
    li {
      &:first-child {
        //border-left: 1px solid @top-nav-border-color
      }
      //border-right: 1px solid @top-nav-border-color;
      border-top: 0px;
      border-bottom: 0px;
      float: @top-nav-align;
      a {
        display: inline-block;
        font-weight: inherit;
        font-size: inherit;
        font-family: inherit;
        color: @top-nav-social-color;
        padding: .5em .5em;
        border-right: 1px solid @brand-primary;
        &:last-child{
          border-right: 0px solid transparent;
        }
        &:hover {
          color: @top-nav-hover-color;
        }
      }
    }
  }
  .social-links {
    vertical-align: bottom;
    display: inline-block;
    //margin-left: 1em;
    width: auto;
    font-size: 1.25em;

    .social-link {
      color: @top-nav-social-color;
      background: @top-nav-social-hover;
      border-radius: 100%;
      //border-right: 1px solid @top-nav-border-color;
      i {
        margin-right: 0;
        color: @top-nav-social-color;
      }

      &:hover, &:focus, &:active {
        color: @top-nav-social-hover;
        background: @top-nav-social-color;
        &:before {
          //border-bottom-color: @top-nav-social-hover;
        }
        &:after {
          //border-top-color: @top-nav-social-hover;
        }
        i {
          color: @top-nav-social-hover;
        }
      }
    }
  }
}


