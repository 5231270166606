// for the feed

section.team-members-section {

  div.team-column {
    padding: (@grid-gutter-width/4);
  }

  div.team-member {
    text-align: center;
    background: @brand-text-on-dark;
    padding: (@grid-gutter-width* .5);
    box-shadow: 0px 10px 20px 0px fade(@brand-gray-dark, 20%);
    position: relative;
    border-radius: .6em;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;

    font-size: .875em;


    a.btn {
      margin-top: auto;
      justify-self: flex-end;
    }
    .team-card {
      padding-left: (@grid-gutter-width* .25);
      padding-right: (@grid-gutter-width* .25);

    }

    div.team-image-cont {
      position: relative;
      background: #fff;
      width: 100%;
      padding-bottom: 100%;
      overflow: hidden;
      margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);

    }
    div.team-image-wrap {
      position:absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      text-align:center;
      clip-path: url('#HEXBOX');
      &:before {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: fade(@brand-primary,40%);
        transition:background .5s;
        content: '';
        display: block;
      }
    }
    &:hover {
      div.team-image-wrap:before {
        background: fade(@brand-primary,0%);
      }
    }
    .team-image {
      object-fit: cover;
      max-width: none !important;
      width: 100% !important;
      height: 100% !important;
      object-position: 50% 50%;

    }

    div.team-member-content {
      text-align:center;
      h3 span {
        font-weight: 300;
        width :100%;
        display: inline-block;
      }
    }


    a.btn.btn-default {
      color: @brand-text-on-dark;

      &:hover {
        color: @brand-primary;
      }
    }

  }

}


//div.hex-container {
//  position:absolute;
//  left: 0;
//  top: 0;
//  width: 100%;
//  overflow: hidden;
//  height: 100%;
//  padding-top: 10.6%;
//  box-sizing: border-box;
//  background: white;
//  z-index: 2;
//}
//@hex-size : 45%;
//.roundHex {
//
//  position:relative;
//  margin:0 auto;
//  background:#000;
//  border-radius:5%;
//  height: 0;
//  padding-bottom: (@hex-size * 1.666666666666667);
//  width: @hex-size;
//  transition:all 1s;
//  transform-origin: center center;
//  transform: rotate(90deg);
//  .roundHex {
//    z-index: 1;
//
//  }
//  &.two,&.three {
//    left: 50%;
//    top: 48%;
//    position: absolute;
//
//  }
//  &.two {
//    transform: translateX(-50%) translateY(-50%) rotate(90deg) scale(1.05,1.05);
//    background: #fff;
//    z-index: -1;
//  }
//  &.three{
//    transform: translateX(-50%) translateY(-50%) rotate(90deg) scale(1.1,1.1);
//    background: #000;
//    z-index: -2;
//  }
//}
//.roundHex:before, .roundHex:after {
//  content:"";
//  position:absolute;
//  top:0;
//  left:0;
//  background:inherit;
//  border-radius:inherit;
//  height:100%;
//  width:100%;
//  top:0;
//  left:0;
//}
//.roundHex:before {
//  -webkit-transform:rotate(60deg);
//  -moz-transform:rotate(60deg);
//  transform:rotate(60deg);
//  transition:all 1s 0.5s;
//}
//.roundHex:after {
//  -webkit-transform:rotate(-60deg);
//  -moz-transform:rotate(-60deg);
//  transform:rotate(-60deg);
//  transition:all 1s 1s;
//}
//



// for the single

div#content.teams-content {
  div.team-image-cont {
    position: relative;
    background: #fff;
    width: 200px;
    padding-bottom: 200px;
    overflow: hidden;
    margin-bottom: (@grid-gutter-width * @paragraph-margin-factor);
    @media(max-width: @screen-md-min){
      margin-left: (@grid-gutter-width/2);
    }
  }
  div.team-image-wrap {
    position:absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-align:center;
    clip-path: url('#HEXBOX');
  }
  .team-image {
    object-fit: cover;
    max-width: none !important;
    width: 100% !important;
    height: 100% !important;
    object-position: 50% 50%;

  }
}

