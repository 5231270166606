#content {
  .featured-image-article {
    width: 100%;
    display: inline-block;
    img {
      margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
    }
  }
  .feed-content {
    margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
  }
}

.feed-header {
  margin: 0 0 (@grid-gutter-width * @paragraph-margin-factor);
}
.feed-article {
  margin: 0 0 (@grid-gutter-width );
}


.category-alt {
  .feed-article {
    display: inline-flex;
    flex-wrap: wrap;
    margin-top: 1em;
    //align-items: center;//
    .image-container {
      width: 200px;
      padding: 1em;
      img {
        object-fit: cover;
        object-position: center center;
        max-width: none;
        width: 100%;
      }
    }
    .content-container {
      padding-left : 1em;
    }
    .feed-header {}
    .feed-content {}
  }
}


