
.row.client-logos {
  .column {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;

    .pt-image {
      width: 150px;
      padding: .75em;
      display: inline-block;
      margin-bottom: 0;
    }

  }
}


// TESTIMONIALS CODE
// TESTIMONIALS CODE
// TESTIMONIALS CODE
// TESTIMONIALS CODE
// TESTIMONIALS CODE
// TESTIMONIALS CODE
// TESTIMONIALS CODE
// TESTIMONIALS CODE


section {
  .strong-view {
    .wpmslider-viewport {
      border: 0px solid transparent !important;
    }
  }

  .strong-view.default .testimonial {
    border: 0px solid transparent !important;

    .testimonial-inner {
      background: transparent;
      border: 0px solid transparent;
      //border-radius: 1em;
      text-align: left;
      font-size: 1em;
      line-height: 1.75em;

      padding: (@grid-gutter-width * .5 ) (@grid-gutter-width * 1 );
      //color: @brand-text-on-dark;
      position: relative;
      //&:before {
      //  content:'\201C';
      //  position:absolute;
      //  left: 0;
      //  top: 0;
      //  color: @brand-primary;
      //  font-size: 5em;
      //  line-height: 0.9;
      //}
      //&:after {
      //  content:'\201D';
      //  position:absolute;
      //  right: 0;
      //  bottom: 0.4125em;
      //  color: @brand-primary;
      //  font-size: 5em;
      //  line-height: 0.9;
      //}

      p {
        //padding-left: 1em;
        //border-left: 1px solid @brand-primary;
        //color: @brand-text-on-dark;
      }

      .testimonial-name {
        margin-top: (@grid-gutter-width/2);
        //color: @brand-text-on-dark;
        font-style: none;
        font-weight: 400;
        font-size: 1em;
        //line-height: 1;
        font-family: @headings-font-family;
        text-align: center;

      }

      .testimonial-company {
        font: @headings-font-family;
        font-style: italic;
        font-size: 1em;
        //line-height: 1;
        font-weight: 100;
        text-align: center;
      }
    }
  }

  .strong-view .wpmslider-controls {
    .wpmslider-controls-direction {
      a {
        text-decoration: none;
        //color: @brand-text-on-dark;

        &:hover {
          text-decoration: none;
          color: @brand-primary;
        }
      }
    }

    .wpmslider-pager-item {
      a, a:before {
        //color: @brand-text-on-dark;
      }
    }
  }
}

#live-area .row.icon-row {
  .column .pt-image {
    img {
      max-width: 100px;
      display: inline-block;
    }
  }
}


#live-area, #content {
  section .row.icon-block {
    .pt-image {
      width: 100%;
      text-align: inherit;

      img {
        display: inline-block;
        max-width: (@grid-gutter-width * 4);
      }
    }
  }

  section .row.logos-row {
    .column {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
    }

    .pt-image {
      box-sizing: border-box;
      padding: (@grid-gutter-width/2) (@grid-gutter-width/4);
      width: 220px;
      margin-bottom: 0px;

      img {
        margin-bottom: 0px;
      }
    }
  }
}

.big-number p {
  margin-bottom: 0;

}

.big-number p span.inline-span {
  font-size: 5em;
  font-weight: 800;
  line-height: 1.125em
}


.row.white-squared-divs {
  @media(min-width: @screen-md-min) {
    .column {
      padding-left: (@grid-gutter-width* .25);
      padding-right: (@grid-gutter-width* .25);

      .pt-ds {
      }
    }
  }
  .column {
    .pt-ds {
      background: @brand-text-on-dark;
      padding: (@grid-gutter-width* .5);
      box-shadow: 0px 10px 20px 0px fade(@brand-gray-dark, 20%);
      position: relative;
      border-radius: .6em;
      a.btn.btn-default {
        color: @brand-text-on-dark;
        &:hover {
          color :@brand-primary;
        }
      }
      //&:before, &:after {
      //  content: '';
      //  width: 100%;
      //  padding-bottom: 100%;
      //  background: red;
      //  display: block;
      //  transform: rotate(45deg);
      //  position: absolute;
      //  left: 50%;
      //  margin-left: -50%;
      //
      //}
      //
      //&:after {
      //  top: 100%;
      //
      //}
      //
      //&:before {
      //  top: 0;
      //}
    }
  }
}




.Values  .row.white-squared-divs {}

.white-card {

        background: @brand-text-on-dark;
        padding: (@grid-gutter-width* .5);
        box-shadow: 0px 10px 20px 0px fade(@brand-gray-dark, 20%);
        position: relative;
        border-radius: .6em;
        margin-bottom: 1.5em;
  a.btn.btn-default {
    color: @brand-text-on-dark;
    &:hover {
      color :@brand-primary;
    }
  }



        //&:before, &:after {
        //  content: '';
        //  width: 100%;
        //  padding-bottom: 100%;
        //  background: red;
        //  display: block;
        //  transform: rotate(45deg);
        //  position: absolute;
        //  left: 50%;
        //  margin-left: -50%;
        //
        //}
        //
        //&:after {
        //  top: 100%;
        //
        //}
        //
        //&:before {
        //  top: 0;
        //}
}


@media only screen and (max-width: 700px) {
  .article-card {
    a.btn.btn-default {
      color: @brand-text-on-dark;
      margin-bottom: .5em;

      &:hover {
        color: @brand-primary;
      }
    }
  }


  //&:before, &:after {
  //  content: '';
  //  width: 100%;
  //  padding-bottom: 100%;
  //  background: red;
  //  display: block;
  //  transform: rotate(45deg);
  //  position: absolute;
  //  left: 50%;
  //  margin-left: -50%;
  //
  //}
  //
  //&:after {
  //  top: 100%;
  //
  //}
  //
  //&:before {
  //  top: 0;
  //}
}

#our-process {
  .infographic-row {
    .infographic-step-column {
      // for the colorization
      &.first {
        @local-color: #1cd1b3;
        h3 {
          color: @local-color;
        }
        .process-flow-row .column ul li:before{
          color: @local-color;
        }
      }
      &.second {
         @local-color: @brand-tertiary;
        h3 {
          color: @local-color;
        }
        .process-flow-row .column ul li:before{
          color: @local-color;
        }
      }
      &.third {
        @local-color: @brand-secondary;

        h3 {
          color: @local-color;
        }
        .process-flow-row .column ul li:before{
          color: @local-color;
        }
      }
      &.fourth {
        @local-color: @brand-primary;
        h3 {
          color: @local-color;
        }
        .process-flow-row .column ul li:before{
          color: @local-color;
        }
      }
    }

    .infographic-header {
      .column {
        padding-left: 0;
        padding-right: 0;
        @media(max-width:@screen-sm-min){
          padding-left: (@grid-gutter-width/2);
          padding-right: (@grid-gutter-width/2);
        }
        display: inline-flex;
        flex-wrap: nowrap;
        align-items: center;
        //.bs-flex-fix();
      }
      .text-object {
        padding:0 (@grid-gutter-width/2);
        padding-left: 0em;
        width: 100%;
        width: calc(100% - 100px);
        @media(min-width:@screen-lg-min){
          width: calc(100% - 150px);

        }
      }
      h2 {
        margin-bottom: .125em;
      }
      h3 {
        font-weight: 400;
        margin-bottom: 0px;
      }
      .pt-image {
        width: 100px;
        margin-bottom: 0px;
        @media(min-width:@screen-lg-min){
          width: 150px;
        }
        img {
          max-width: 100%;
        }
      }
    }
    .process-flow-row {
      .column {
        padding-left: 0;
        padding-right: 0;
        @media(max-width:@screen-sm-min){
          padding: 0 (@grid-gutter-width/2);
        }

      }
      .pt-image {
        @media(max-width: @screen-md-min){
          max-width: 240px;
        }

        img {}
      }
      .text-object {
        padding-left: .5em;
        padding-right: .5em;
        h4 {}


      }
    }

  }
}



//--------------------------------------------------- META SLIDER
#live-area div.metaslider {
  div.flexslider {
    margin-bottom: 0px;
  }
  ul.slides {
    border: 0;
    margin: 0;
    list-style-type: none;
    list-style: none;
    padding: 0;
    line-height: normal;
    max-width: none;
    max-height: none;
    li {
      border: 0;
      margin: 0;
      margin-right: 0px;
      margin-bottom: 0px;
      list-style-type: none;
      list-style: none;
      padding: 0;
      padding-left: 0px;
      line-height: normal;
      max-width: none;
      max-height: none;
      overflow: hidden;
      img {
        width: 100%;

      }
    }
  }
  ul.slides li {
    &.flex-active-slide {
    }
  }
  div.caption-wrap {
    font-weight: 300;
    font-family: @headings-font-family;
    color: @brand-black;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    strong {
      font-weight: 700;
      display: inline-block;
      width: 100%;
      clear: both;
      font-size: 1.5em;
    }
    cite {
      width: 100%;
      display: inline-block;
      text-align: center;
      font-size: .75em;
    }
    top: 50%;
    left: 50%;
    height: 200px;
    div.caption {
      padding: 0 0 0 0;
      width: 100%;
    }
    margin-top: -100px;
    text-align: center;
    background: transparent;
    opacity: 1;
    text-shadow: none;
    box-sizing: border-box;
    @media (min-width: 0px) {
      padding-left: (@grid-gutter-width * .5);
      padding-right: (@grid-gutter-width * .5);
      font-size: 1.5em;
      width: 320px;
      margin-left: -160px;
    }
    @media (min-width: @screen-xs) {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.75em;
      width: (440px);
      margin-left: ((440px ) * -0.5);
    }
    @media (min-width: @screen-sm-min) {
      padding-left: 0;
      padding-right: 0;
      font-size: 1.75em;
      width: @container-sm;
      margin-left: (@container-sm * -0.5);
    }
    @media (min-width: @screen-md-min) {
      font-size: 2em;
      width: @container-md;
      margin-left: (@container-md * -0.5);
    }
    @media (min-width: @screen-lg-min) {
      font-size: 2.25em;
      width: @container-lg;
      margin-left: (@container-lg * -0.5);
    }
  }
  ol.flex-control-nav.flex-control-paging {
    font-size: 1em;
    //@media (min-width: @screen-sm-min) {
    //  font-size: 1.5em;
    //}
    //@media (min-width: @screen-md-min) {
    //  font-size: 2.5em;
    //}
    //@media (min-width: @screen-lg-min) {
    //  font-size: 3.5em;
    //}
    width: 100%;
    bottom: 0em;
    text-align: center;
    margin-bottom: 0;

    li {
      width: 1em;
      height: .333em;
      display: inline-block;
      float: none;
      padding: 0 0 0 0;
      position: relative;
      margin-left: .125em;
      margin-right: .125em;

      a {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 0px;
        background: @brand-black;

        &.flex-active {
          background: @brand-primary;
        }
      }
    }
  }
}

#live-area div.metaslider {
  img {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  ul.slides {
    margin-bottom: 0px;
    margin-top: 0px;
    li {
      padding-left: 0px;
      margin-left: 0px;
      margin-bottom: 0px;
    }
  }
}


.testimonial-row  {
  display: flex;
  align-items: flex-start;
  @media(min-width: @screen-md-min){
    align-items: center;
  }
  flex-wrap: wrap;
  .bs-flex-fix();
  .person {
    text-align: center;
    .pt-image {
      padding: 5px;
      background: #fff;
      display: inline-block;
      width: auto;
      max-width: 320px;
      transform: rotateZ(-3deg);
      box-shadow: 0px 10px 21px 0px rgba(0,0,0,.25);
    }
    &.left {
      .pt-image {
        transform: rotateZ(3deg);
      }
    }
  }

  @media(max-width: @screen-md-min){
    .column {
      order: 1;
    }
    .column.person {order: 0;}

  }
}
.column.shadow-special  {
  .pt-image{
  filter: drop-shadow(5px 5px rgba(0,0,0,.25));
  }
}
